
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    valor_item:0,
    user:'',
    itens_lancamento_ok:false,
    token:false,
    menu:false,
    
  },
  
  actions: {
    
    
    SetStatusLanc({commit}, obj) {
      commit('SETLANC', obj)
    },
    addLanc({commit}, obj) {
      commit('ADDLANC', obj)
    },
    addMenu ({commit}, obj) {
      commit('ADDMENU', obj)
    },
    
    addItem ({commit}, obj) {
      commit('ADDITEM', obj)
    },
    alterValor({commit}, obj) {
      commit('ALERTVALOR', obj)
    },
    addToken({commit}, obj) {
      commit('ADDTOKEN', obj)
    },
    alterItem({commit}, obj  ) {
      commit('ALTERITEM', obj )
    },
    removeItem({commit}, obj) {
      commit('REMOVEITEM', obj)
    },
    addUser({commit}, obj) {
      commit('ADDUSER', obj)
    },
    
  },
  mutations: {

    ADDUSER(state, obj) {
      state.user=obj
    },
    ALERTVALOR(state, obj) {
      state.itens_lancamento.valor=obj
    },
    ADDLANC(state, obj) {
      state.itens_lancamento=obj
    },
    SETLANC(state, obj) {
      state.itens_lancamento_ok=obj
    },
    ADDITEM(state, obj) {
       let val = obj.replace('.','')     
       let val2 = val.replace(',','.')    
      let ret = parseFloat(state.valor_item) + parseFloat(val2);
      state.valor_item = ret.toFixed(2)
      
    },
    REMOVEITEM(state, obj) {
      console.log('index'+ obj.indx );
      state.itens_lancamento.splice(obj.indx,1)
    },   
    ALTERITEM(state, obj) {
      let val = obj.replace('.','')     
      let val2 = val.replace(',','.')          
      let ret = parseFloat(state.valor_item) - parseFloat(val2);
      state.valor_item = ret.toFixed(2)
   
    },
    ADDTOKEN(state, obj) {
      state.token = obj
    },
    ADDMENU(state, obj) {
      state.menu = obj
    }

  }
})

