import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify";

import VueAxios from "./axios";
//import axios from 'axios'
import pt from 'vuetify/es5/locale/pt'
import VueMask from 'di-vue-mask'
import ViaCep from "vue-viacep"

import { preset } from 'vue-cli-plugin-vuetify-preset-reply/preset'
Vue.use(Vuetify);
Vue.use(VueMask);
Vue.use(ViaCep)

export default new Vuetify({
 // preset,
  lang: {
    locales: {
      pt

    },
    current: 'pt'
  },
  icons: {
    iconfont: "md" // default - only for display purposes
  },
  theme: {
    themes: {
      dark:true,
      light: {
        primary: "#187052",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
        botao_sucesso: "#187052",
        success:'#187052',
        bota_error: "#E65100",
        branco:"#FFF",
        topo_menu:"#E9E9E9",
        fundo:'#E5E5E5',
        fundo_tabela:'#F1F4F2',
        inputColor:'#f5f5f5',
        //cor_menu_topo:"#0C6910",
        cor_menu_topo:"red",
        caixa:"#5C9B62",
        caixa_2:"#A86706",
        caixa_3:"#890303",
        caixa_4:"#045887",
        caixa_5:"#67430D",
        caixa_6:"#0B6640",
        caixa_7:"#A86706",
        caixa_8:"#890303",
        caixa_9:"#045887",
        caixa_10:"#67430D",
        bt_pagar:'#B15555',
        bt_receber:'#1C4694',
        bt_transferir:'#E98B34',
        bt_lanc:'#358447'

      }
    }
  }


});