import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "login",

    component: () =>
      import(
        "@/components/Login.vue"
      ) /*import( /* webpackChunkName: "about"  './Login.vue')*/
  },
 
  {
    path: "/dashboard",
    name: "Dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("@/components/Dashboard.vue"),
    children: [
       {
        path: "principal",
        component: () => import("../views/Principal.vue")
      },     
      {
        path: "lancamentos",
        component: () => import("../views/Lancamentos.vue")
      },
       {
        path: "consulta_lancamentos",
        component: () => import("../views/ConsultaLancamentos.vue")
      },
      {
        path: "cadastro/historico_padrao",
        component: () => import("../views/HistoricoPadrao.vue")
        
      },
       {
        path: "cadastro/grupo_financeiro",
        component: () => import("../views/GrupoFinanceiro.vue")
        
      }
      ,{
        path: "cadastro/status",
        component: () => import("../views/Status.vue")
        
      },
      ,{
        path: "cadastro/empresa",
        component: () => import("../views/Empresa.vue")
        
      },
       ,{
        path: "cadastro/funcionarios",
        component: () => import("../views/Funcionarios.vue")
        
      },
        ,{
        path: "cadastro/permissoes",
        component: () => import("../views/Permissoes.vue")
        
      },
      ,{
        path: "cadastro/fornecedores",
        component: () => import("../views/Fornecedores.vue")
        
      }
       ,{
        path: "extrato",
        component: () => import("../views/Extrato.vue")
        
      },
      ,{
        path: "resultado_operacional",
        component: () => import("../views/ResultadoOperacional.vue")
        
      }
      ,{
        path: "cadastro/contas",
        component: () => import("../views/Contas.vue")
        
      }
     
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  const checkLogado = window.sessionStorage.getItem('token'); // store.state.token
  //console.log(to)
    if (to.name !== "login") {
      if (checkLogado=="") {
        
        next("/");

        //next("/home");
      } else {
        console.log("logado");
        next();
      }
    }
      next();
      
  })

export default router;